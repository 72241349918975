import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FaCheck, FaTimes, FaArrowRight } from 'react-icons/fa';

const Pricing: React.FC = () => {
  const [isAnnual, setIsAnnual] = useState(false);

  const plans = [
    {
      name: 'Starter',
      description: 'Perfect for small businesses starting their digital journey',
      monthlyPrice: 499,
      annualPrice: 449,
      features: [
        { text: 'Basic SEO Optimization', included: true },
        { text: 'Social Media Management (2 platforms)', included: true },
        { text: 'Monthly Performance Reports', included: true },
        { text: 'Content Creation (2 posts/week)', included: true },
        { text: 'Email Marketing', included: false },
        { text: 'Advanced Analytics', included: false },
        { text: 'Dedicated Account Manager', included: false },
      ],
      color: 'from-blue-400 to-blue-600',
      popular: false,
    },
    {
      name: 'Professional',
      description: 'Ideal for growing businesses seeking comprehensive marketing',
      monthlyPrice: 999,
      annualPrice: 899,
      features: [
        { text: 'Advanced SEO Optimization', included: true },
        { text: 'Social Media Management (4 platforms)', included: true },
        { text: 'Weekly Performance Reports', included: true },
        { text: 'Content Creation (5 posts/week)', included: true },
        { text: 'Email Marketing', included: true },
        { text: 'Advanced Analytics', included: true },
        { text: 'Dedicated Account Manager', included: false },
      ],
      color: 'from-primary-500 to-primary-700',
      popular: true,
    },
    {
      name: 'Enterprise',
      description: 'Custom solutions for large organizations',
      monthlyPrice: 1999,
      annualPrice: 1799,
      features: [
        { text: 'Custom SEO Strategy', included: true },
        { text: 'Social Media Management (All platforms)', included: true },
        { text: 'Real-time Performance Dashboard', included: true },
        { text: 'Custom Content Strategy', included: true },
        { text: 'Email Marketing Automation', included: true },
        { text: 'Advanced Analytics & AI Insights', included: true },
        { text: 'Dedicated Account Manager', included: true },
      ],
      color: 'from-purple-500 to-purple-700',
      popular: false,
    },
  ];

  return (
    <section className="section-padding relative overflow-hidden" id="pricing">
      {/* Background Elements */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute -top-24 -right-24 w-96 h-96 bg-primary-100 rounded-full opacity-20 blur-3xl" />
        <div className="absolute -bottom-24 -left-24 w-96 h-96 bg-purple-100 rounded-full opacity-20 blur-3xl" />
      </div>

      <div className="max-w-7xl mx-auto relative">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold mb-4">
            <span className="gradient-text">Flexible Pricing Plans</span>
          </h2>
          <p className="text-lg text-secondary-600 max-w-2xl mx-auto mb-8">
            Choose the perfect plan for your business needs. Save up to 10% with annual billing.
          </p>

          {/* Billing Toggle */}
          <div className="flex items-center justify-center gap-4">
            <span className={`text-sm ${!isAnnual ? 'text-primary-600 font-semibold' : 'text-secondary-600'}`}>
              Monthly Billing
            </span>
            <button
              onClick={() => setIsAnnual(!isAnnual)}
              className="relative w-16 h-8 rounded-full bg-primary-100 p-1 transition-colors duration-300"
            >
              <motion.div
                animate={{ x: isAnnual ? 32 : 0 }}
                transition={{ type: 'spring', stiffness: 500, damping: 30 }}
                className="w-6 h-6 rounded-full bg-primary-600"
              />
            </button>
            <span className={`text-sm ${isAnnual ? 'text-primary-600 font-semibold' : 'text-secondary-600'}`}>
              Annual Billing
            </span>
          </div>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {plans.map((plan, index) => (
            <motion.div
              key={plan.name}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className={`relative rounded-2xl bg-white shadow-xl overflow-hidden ${
                plan.popular ? 'ring-2 ring-primary-500 scale-105' : ''
              }`}
            >
              {plan.popular && (
                <div className="absolute top-0 right-0">
                  <div className="text-xs font-semibold text-white px-3 py-1 bg-primary-500 rounded-bl-lg">
                    Most Popular
                  </div>
                </div>
              )}

              <div className="p-8">
                <h3 className="text-2xl font-bold mb-2">{plan.name}</h3>
                <p className="text-secondary-600 mb-6">{plan.description}</p>
                <div className="mb-8">
                  <div className="flex items-baseline">
                    <span className="text-4xl font-bold">
                      ${isAnnual ? plan.annualPrice : plan.monthlyPrice}
                    </span>
                    <span className="text-secondary-600 ml-2">/month</span>
                  </div>
                  {isAnnual && (
                    <p className="text-sm text-primary-600 mt-2">
                      Save ${(plan.monthlyPrice - plan.annualPrice) * 12}/year
                    </p>
                  )}
                </div>

                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  className={`w-full py-3 px-6 rounded-lg bg-gradient-to-r ${plan.color} 
                    text-white font-semibold mb-8 transition-transform duration-300 
                    hover:shadow-lg flex items-center justify-center gap-2`}
                >
                  Get Started <FaArrowRight className="text-sm" />
                </motion.button>

                <div className="space-y-4">
                  {plan.features.map((feature, index) => (
                    <div key={index} className="flex items-center gap-3">
                      {feature.included ? (
                        <FaCheck className="text-green-500 flex-shrink-0" />
                      ) : (
                        <FaTimes className="text-secondary-400 flex-shrink-0" />
                      )}
                      <span className={feature.included ? 'text-secondary-800' : 'text-secondary-400'}>
                        {feature.text}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Pricing;
