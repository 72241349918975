import React from 'react';
import { motion } from 'framer-motion';
import { FaSearch, FaHashtag, FaChartLine, FaCogs, FaLink, FaPencilAlt } from 'react-icons/fa';

const Services: React.FC = () => {
  const services = [
    {
      icon: <FaSearch className="w-8 h-8" />,
      title: 'SEO Optimization',
      description: 'Boost your website rankings with our comprehensive SEO services including keyword research, on-page optimization, and technical SEO.',
    },
    {
      icon: <FaHashtag className="w-8 h-8" />,
      title: 'Social Media Marketing',
      description: 'Engage your audience and build brand awareness through strategic social media campaigns and content management.',
    },
    {
      icon: <FaChartLine className="w-8 h-8" />,
      title: 'Analytics & Reporting',
      description: 'Get detailed insights into your digital performance with comprehensive analytics and regular performance reports.',
    },
    {
      icon: <FaCogs className="w-8 h-8" />,
      title: 'Technical SEO',
      description: 'Optimize your website\'s technical aspects including site speed, mobile responsiveness, and crawlability.',
    },
    {
      icon: <FaLink className="w-8 h-8" />,
      title: 'Link Building',
      description: 'Build authority and improve rankings with our strategic link building services and outreach campaigns.',
    },
    {
      icon: <FaPencilAlt className="w-8 h-8" />,
      title: 'Content Strategy',
      description: 'Develop engaging content that resonates with your audience and drives organic traffic to your website.',
    },
  ];

  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <section className="section-padding bg-gray-50" id="services">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-16">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className="text-4xl font-bold mb-4"
          >
            <span className="gradient-text">Our Services</span>
          </motion.h2>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="text-lg text-secondary-600 max-w-2xl mx-auto"
          >
            Comprehensive digital marketing solutions tailored to grow your online presence
            and drive measurable results.
          </motion.p>
        </div>

        <motion.div
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
        >
          {services.map((service, index) => (
            <motion.div
              key={index}
              variants={itemVariants}
              className="card group hover:bg-primary-600 hover:text-white transition-all duration-300"
            >
              <div className="text-primary-600 group-hover:text-white mb-4">
                {service.icon}
              </div>
              <h3 className="text-xl font-semibold mb-3">{service.title}</h3>
              <p className="text-secondary-600 group-hover:text-white/90">
                {service.description}
              </p>
            </motion.div>
          ))}
        </motion.div>

        {/* Service Process Steps */}
        <div className="mt-24">
          <motion.h3
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className="text-3xl font-bold text-center mb-12"
          >
            Our Process
          </motion.h3>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            {[
              { step: '01', title: 'Analysis', description: 'In-depth analysis of your current digital presence' },
              { step: '02', title: 'Strategy', description: 'Develop a customized marketing strategy' },
              { step: '03', title: 'Implementation', description: 'Execute the planned strategies effectively' },
              { step: '04', title: 'Optimization', description: 'Continuous monitoring and optimization' },
            ].map((step, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="text-center"
              >
                <div className="w-16 h-16 rounded-full bg-primary-100 flex items-center justify-center mx-auto mb-4">
                  <span className="text-xl font-bold text-primary-600">{step.step}</span>
                </div>
                <h4 className="text-xl font-semibold mb-2">{step.title}</h4>
                <p className="text-secondary-600">{step.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
