import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaSearchPlus, FaLink, FaChartLine } from 'react-icons/fa';

const Portfolio: React.FC = () => {
  const [selectedCategory, setSelectedCategory] = useState('all');

  const categories = [
    { id: 'all', name: 'All Projects' },
    { id: 'seo', name: 'SEO Campaigns' },
    { id: 'social', name: 'Social Media' },
    { id: 'content', name: 'Content Marketing' },
  ];

  const projects = [
    {
      id: 1,
      title: 'E-commerce SEO Success',
      category: 'seo',
      image: 'https://images.unsplash.com/photo-1661956602116-aa6865609028?ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=800&q=80',
      stats: { traffic: '+150%', ranking: '+45 Keywords', duration: '6 months' },
      description: 'Increased organic traffic by 150% for an e-commerce client through comprehensive SEO strategy.',
    },
    {
      id: 2,
      title: 'Social Media Growth',
      category: 'social',
      image: 'https://images.unsplash.com/photo-1611162617474-5b21e879e113?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=800&q=80',
      stats: { engagement: '+200%', followers: '+50K', duration: '3 months' },
      description: 'Achieved significant social media growth for a lifestyle brand through targeted campaigns.',
    },
    {
      id: 3,
      title: 'Content Strategy',
      category: 'content',
      image: 'https://images.unsplash.com/photo-1542744173-8e7e53415bb0?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=800&q=80',
      stats: { traffic: '+80%', conversion: '+45%', duration: '4 months' },
      description: 'Developed and executed a content strategy that increased conversions by 45%.',
    },
    {
      id: 4,
      title: 'Local SEO Campaign',
      category: 'seo',
      image: 'https://images.unsplash.com/photo-1497366216548-37526070297c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=800&q=80',
      stats: { visibility: '+200%', leads: '+75%', duration: '5 months' },
      description: 'Improved local search visibility for a chain of restaurants across multiple locations.',
    },
    {
      id: 5,
      title: 'Instagram Campaign',
      category: 'social',
      image: 'https://images.unsplash.com/photo-1563986768609-322da13575f3?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=800&q=80',
      stats: { reach: '+300%', sales: '+85%', duration: '2 months' },
      description: 'Successful Instagram campaign that resulted in significant sales increase.',
    },
    {
      id: 6,
      title: 'Blog Optimization',
      category: 'content',
      image: 'https://images.unsplash.com/photo-1552664730-d307ca884978?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=800&q=80',
      stats: { traffic: '+120%', engagement: '+90%', duration: '6 months' },
      description: 'Optimized blog content strategy resulting in increased traffic and user engagement.',
    },
  ];

  const filteredProjects = projects.filter(
    (project) => selectedCategory === 'all' || project.category === selectedCategory
  );

  return (
    <section className="section-padding bg-gray-50" id="portfolio">
      <div className="max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold mb-4">
            <span className="gradient-text">Our Portfolio</span>
          </h2>
          <p className="text-lg text-secondary-600 max-w-2xl mx-auto">
            Explore our successful digital marketing campaigns and see how we've helped
            businesses achieve their goals.
          </p>
        </motion.div>

        {/* Category Filter */}
        <div className="flex flex-wrap justify-center gap-4 mb-12">
          {categories.map((category) => (
            <motion.button
              key={category.id}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setSelectedCategory(category.id)}
              className={`px-6 py-2 rounded-full transition-all duration-300 ${
                selectedCategory === category.id
                  ? 'bg-primary-600 text-white shadow-lg'
                  : 'bg-white text-secondary-600 hover:bg-primary-50'
              }`}
            >
              {category.name}
            </motion.button>
          ))}
        </div>

        {/* Projects Grid */}
        <motion.div
          layout
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
        >
          <AnimatePresence>
            {filteredProjects.map((project) => (
              <motion.div
                key={project.id}
                layout
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.9 }}
                transition={{ duration: 0.3 }}
                className="group relative overflow-hidden rounded-xl bg-white shadow-lg"
              >
                {/* Project Image */}
                <div className="relative h-64 overflow-hidden">
                  <img
                    src={project.image}
                    alt={project.title}
                    className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-110"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/70 via-black/50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                  
                  {/* Hover Overlay */}
                  <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    <motion.div
                      initial={{ y: 20 }}
                      whileHover={{ y: 0 }}
                      className="text-white text-center p-6"
                    >
                      <h3 className="text-xl font-bold mb-2">{project.title}</h3>
                      <p className="text-sm mb-4">{project.description}</p>
                      <div className="flex justify-center space-x-4">
                        {Object.entries(project.stats).map(([key, value]) => (
                          <div key={key} className="text-center">
                            <div className="text-lg font-bold">{value}</div>
                            <div className="text-xs uppercase">{key}</div>
                          </div>
                        ))}
                      </div>
                    </motion.div>
                  </div>
                </div>

                {/* Project Info */}
                <div className="p-6">
                  <h3 className="text-xl font-bold mb-2">{project.title}</h3>
                  <div className="flex items-center justify-between">
                    <span className="text-primary-600 capitalize">{project.category}</span>
                    <div className="flex space-x-3">
                      <FaChartLine className="text-secondary-400 hover:text-primary-600 transition-colors duration-300" />
                      <FaLink className="text-secondary-400 hover:text-primary-600 transition-colors duration-300" />
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
          </AnimatePresence>
        </motion.div>
      </div>
    </section>
  );
};

export default Portfolio;
